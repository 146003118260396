import React from 'react'

import video from "../../static/assets/vid.mp4"
import video2 from "../../static/assets/vid2.mp4"
import video3 from "../../static/assets/vid3.mp4"
import video4 from "../../static/assets/vid4.mp4"
import video5 from "../../static/assets/vid5.mp4"
import tb1 from "../../static/assets/meh.jpg"
import tb2 from "../../static/assets/sol.jpg"
import tb3 from "../../static/assets/sat.jpg"
import tb4 from "../../static/assets/mit.jpg"
import tb5 from "../../static/assets/emc.jpg"

const Videos = () => {
    return (
        <div>
            <div className="bgImageContainer">
            <video className="fifth" loop preload="none" poster={tb5}>
                <source src={video5} type="video/mp4" />
            </video>
            </div>
            <div className="bgImageContainer2">
            <video className="first" loop preload="none" poster={tb1}>
                <source src={video} type="video/mp4" />
            </video>
            </div>
            <div className="bgImageContainer3">
            <video className="second" loop preload="none" poster={tb2}>
                <source src={video2} type="video/mp4" />
            </video>
            </div>
            <div className="bgImageContainer4">
            <video className="third" loop preload="none" poster={tb3}>
                <source src={video3} type="video/mp4" />
            </video>
            </div>
            <div className="bgImageContainer5">
            <video className="fourth" loop preload="none" poster={tb4}>
                <source src={video4} type="video/mp4" />
            </video>
            </div>
        </div>
    )
}

export default Videos