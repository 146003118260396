'use-strict'

export default function sketch(p){
    var length = Math.floor(window.innerWidth / 7)
    var range = 10

    // Blob class and variables
    let red, yellow, green, purple, blue
    let blobRadius
    var yoff = 0

    // Posicion de los Blobs

    let blueLength, redLength, purpleLength, yellowLength, greenLength, blueFlag, redFlag, purpleFlag, yellowFlag, blueSwitch, redSwitch, purpleSwitch, yellowSwitch, greenSwitch
    
    class Blob {
        // Generador de cada blob
        constructor(x, y, r, perlinOffset) {
            this.r = r // radio de los blobs
            this.pos = {    //Posición de los blobs
                x: x,
                y: y
            };
            this.offset = perlinOffset
        }
        // Generador de las variaciones
        generate() {
            p.push()
            p.translate(this.pos.x, this.pos.y)
            p.beginShape()
            var xoff = 0
            for (var a = 0; a < p.TWO_PI; a+= 0.2) {
                var offset = p.map(p.noise(xoff + this.offset, yoff), 0, 1, -4, 5)
                var r = this.r + offset
                var l = r * Math.cos(a)
                var m = r * Math.sin(a)
                p.vertex(l, m)
                xoff += 0.1
            }
            p.endShape()
            p.pop()

            yoff += 0.02
        }
        // Funcion para mover los circulos
        update(newX, newY) {
            this.pos.x = newX
            this.pos.y = newY
        }
    }

    var d = 10000
    let firstLine, secondLine, thirdLine, fourthLine, fifthLine

    class Line{
        constructor(x, y, caseL){
            this.pos = {
                x: x,
                y: y
            };
            this.caseL = caseL
        }

        draw() {
            switch (this.caseL) {
                case 0:
                    p.stroke(255)
                    var weight = p.map((d/window.innerWidth), 0, 1, 1, 0)
                    if (weight >= 0.9) {
                        var s = 'rgba(255,255,255, ' + ((weight - 0.9)*20) + ')'
                        p.fill(s)
                        p.strokeWeight((weight - 0.9)*0.5)
                        p.textFont('Times New Roman')
                        p.textSize(Math.floor(window.innerWidth/90))
                        p.textAlign(p.CENTER)
                        // p.textStyle(p.ITALIC);
                        p.text('0 1 .  m e  e s t o y  h u n d i e n d o', this.pos.x - window.innerWidth/20, this.pos.y - window.innerHeight/3.8)
                        p.strokeWeight((weight - 0.9)*20)

                        if (d <= 20) {
                            blueFlag = 1
                        } else {
                            blueFlag = 0
                        }

                    } else {
                        p.strokeWeight(0)
                    }
                    p.noFill()
                    p.beginShape()
                    p.curveVertex(this.pos.x, this.pos.y)
                    p.curveVertex(this.pos.x, this.pos.y)
                    p.curveVertex(this.pos.x + window.innerWidth/200, this.pos.y - window.innerHeight/10)
                    p.curveVertex(this.pos.x - window.innerWidth/20, this.pos.y - window.innerHeight/5)
                    p.curveVertex(this.pos.x - window.innerWidth/20, this.pos.y - window.innerHeight/4)
                    p.curveVertex(this.pos.x - window.innerWidth/20, this.pos.y - window.innerHeight/4)
                    p.endShape()
                    break;
                case 1:
                    p.stroke(255)
                    var weight = p.map((d/window.innerWidth), 0, 1, 1, 0)
                    if (weight >= 0.9) {
                        var s = 'rgba(255,255,255, ' + ((weight - 0.9)*20) + ')'
                        p.fill(s)
                        p.strokeWeight((weight - 0.9)*0.5)
                        p.textFont('Times New Roman')
                        p.textSize(Math.floor(window.innerWidth/90))
                        p.textAlign(p.CENTER)
                        p.text('0 2 .  s o l', this.pos.x - window.innerWidth/5, this.pos.y + window.innerHeight/3.7)
                        p.strokeWeight((weight - 0.9)*20)

                        if (d <= 20) {
                            redFlag = 1
                        } else {
                            redFlag = 0
                        }

                    } else {
                        p.strokeWeight(0)
                    }
                    p.noFill()
                    p.beginShape()
                    p.curveVertex(this.pos.x, this.pos.y)
                    p.curveVertex(this.pos.x, this.pos.y)
                    p.curveVertex(this.pos.x - window.innerWidth/20, this.pos.y + window.innerHeight/9)
                    p.curveVertex(this.pos.x - window.innerWidth/7, this.pos.y + window.innerHeight/7)
                    p.curveVertex(this.pos.x - window.innerWidth/5, this.pos.y + window.innerHeight/4)
                    p.curveVertex(this.pos.x - window.innerWidth/6, this.pos.y + window.innerHeight/3)
                    p.endShape()
                    break;
                case 2:
                    p.stroke(255)
                    var weight = p.map((d/window.innerWidth), 0, 1, 1, 0)
                    if (weight >= 0.9) {
                        var s = 'rgba(255,255,255, ' + ((weight - 0.9)*20) + ')'
                        p.fill(s)
                        p.strokeWeight((weight - 0.9)*0.5)
                        p.textFont('Times New Roman')
                        p.textSize(Math.floor(window.innerWidth/90))
                        p.textAlign(p.CENTER)
                        p.text('0 3 . s a t u r n o', this.pos.x + window.innerWidth/70, this.pos.y - window.innerHeight/3.3)
                        p.strokeWeight((weight - 0.9)*20)

                        if (d <= 20) {
                            purpleFlag = 1
                        } else {
                            purpleFlag = 0
                        }

                    } else {
                        p.strokeWeight(0)
                    }
                    p.noFill()
                    p.beginShape()
                    p.curveVertex(this.pos.x, this.pos.y)
                    p.curveVertex(this.pos.x, this.pos.y)
                    p.curveVertex(this.pos.x - window.innerWidth/250, this.pos.y - window.innerHeight/50)
                    p.curveVertex(this.pos.x - window.innerWidth/35, this.pos.y - window.innerHeight/18)
                    p.curveVertex(this.pos.x - window.innerWidth/40, this.pos.y - window.innerHeight/9.5)
                    p.curveVertex(this.pos.x + window.innerWidth/100, this.pos.y - window.innerHeight/6.5)
                    p.curveVertex(this.pos.x + window.innerWidth/120, this.pos.y - window.innerHeight/4.5)
                    p.curveVertex(this.pos.x + window.innerWidth/160, this.pos.y - window.innerHeight/3.85)
                    
                    p.curveVertex(this.pos.x + window.innerWidth/100, this.pos.y - window.innerHeight/3.5)
                    p.curveVertex(this.pos.x + window.innerWidth/100, this.pos.y - window.innerHeight/3.5)
                    p.endShape()
                    break;
                case 3:
                    p.stroke(255)
                    var weight = p.map((d/window.innerWidth), 0, 1, 1, 0)
                    if (weight >= 0.9) {
                        var s = 'rgba(255,255,255, ' + ((weight - 0.9)*20) + ')'
                        p.fill(s)
                        p.strokeWeight((weight - 0.9)*0.5)
                        p.textFont('Times New Roman')
                        p.textSize(Math.floor(window.innerWidth/90))
                        p.textAlign(p.CENTER)
                        p.text('0 4 .  m i t o m a n í a', this.pos.x + window.innerWidth/7, this.pos.y - window.innerHeight/3.8)
                        p.strokeWeight((weight - 0.9)*20)

                        if (d <= 20) {
                            yellowFlag = 1
                        } else {
                            yellowFlag = 0
                        }

                    } else {
                        p.strokeWeight(0)
                    }
                    p.noFill()
                    p.beginShape()
                    p.curveVertex(this.pos.x, this.pos.y)
                    p.curveVertex(this.pos.x, this.pos.y)
                    p.curveVertex(this.pos.x + window.innerWidth/250, this.pos.y - window.innerHeight/70)
                    p.curveVertex(this.pos.x + window.innerWidth/100, this.pos.y - window.innerHeight/15)
                    p.curveVertex(this.pos.x + window.innerWidth/35, this.pos.y - window.innerHeight/6.5)
                    p.curveVertex(this.pos.x + window.innerWidth/16, this.pos.y - window.innerHeight/5.5)
                    p.curveVertex(this.pos.x + window.innerWidth/9, this.pos.y - window.innerHeight/5.4)
                    p.curveVertex(this.pos.x + window.innerWidth/7.5, this.pos.y - window.innerHeight/4.5)
                    p.curveVertex(this.pos.x + window.innerWidth/7, this.pos.y - window.innerHeight/4)
                    p.curveVertex(this.pos.x + window.innerWidth/8, this.pos.y - window.innerHeight/4)
                    p.endShape()
                    break;
                case 4:
                    p.stroke(255)
                    var weight = p.map((d/window.innerWidth), 0, 1, 1, 0)
                    if (weight >= 0.9) {
                        var s = 'rgba(255,255,255, ' + ((weight - 0.9)*20) + ')'
                        p.fill(s)
                        p.strokeWeight((weight - 0.9)*0.5)
                        p.textFont('Times New Roman')
                        p.textSize(Math.floor(window.innerWidth/90))
                        p.textAlign(p.CENTER)
                        p.text('0 5 .  e n  m i  c u a r t o', this.pos.x + window.innerWidth/8, this.pos.y + window.innerHeight/4.5)
                        p.strokeWeight((weight - 0.9)*20)

                    } else {
                        p.strokeWeight(0)
                    }
                    p.noFill()
                    p.beginShape()
                    p.curveVertex(this.pos.x, this.pos.y)
                    p.curveVertex(this.pos.x, this.pos.y)
                    p.curveVertex(this.pos.x + window.innerWidth/15.5, this.pos.y - window.innerHeight/50)
                    p.curveVertex(this.pos.x + window.innerWidth/10.3, this.pos.y - window.innerHeight/11)
                    p.curveVertex(this.pos.x + window.innerWidth/8, this.pos.y - window.innerHeight/8)
                    p.curveVertex(this.pos.x + window.innerWidth/7, this.pos.y - window.innerHeight/13)
                    p.curveVertex(this.pos.x + window.innerWidth/8.3, this.pos.y + window.innerHeight/30)
                    p.curveVertex(this.pos.x + window.innerWidth/7.5, this.pos.y + window.innerHeight/8)

                    p.curveVertex(this.pos.x + window.innerWidth/8, this.pos.y + window.innerHeight/5)
                    p.curveVertex(this.pos.x + window.innerWidth/6.5, this.pos.y + window.innerHeight/5)
                    p.endShape()
                    break;
            }
        }

        update(newX, newY, rx, ry) {
            this.pos.x = newX
            this.pos.y = newY
            
            d = Math.sqrt(Math.pow((this.pos.x - rx), 2) + Math.pow((this.pos.y - ry), 2))
        }
    }

    class Circle{
        constructor(x, y, a = 0){
            this.r = 1 //radio de los círculos
            this.angle = 20 //ángulo de los círculos
            this.range = 0 //rango de los círculos 
            this.v = 2 //velocidad de los círculos
            this.a = 0.2//aceleración de los círculos
            var radian = Math.PI * 2 / 360 * this.angle
            //posición principal de los círculos
            this.pos = {
            x: x,
            y: y
            };
            //línea de referencia central de los círculos
            this.center = {
            x: x,
            y: y
            }
            
            this.switch = true
        }
        getPos(){
            return this.pos
        }
        setPos (x = this.pos.x, y = this.pos.y){
            this.pos.x = x
            this.pos.y = y
        }
        getAngle(){
            return this.angle
        }
        setAngle(angle){
            this.angle = angle
        }
        getVelocity(){
            return this.v
        }
        setVelocity(v){
            this.v = v
        }
        getRange(){
            return this.range
        }
        setRange(range){
            this.range = range
        }
        draw(){
            // p.noStroke()
            p.strokeWeight(1);
            p.stroke(255)
            p.fill('#FFFFFF')
            p.ellipse(this.pos.x, this.pos.y, this.r*10, this.r)
        }
        update(s = 0){
            if(this.range >= 0){
            this.v -= this.a/2 //*1.1, .05
            } 
            else {
            this.v += this.a/2 //*2, 2
            }
            this.range += this.v
            this.pos.y = this.center.y + this.range
            
            if(this.range >= 0){
            this.v += -this.v*0.08 //-this.a*1.5
            }
            //else if(this.v >= 2){
                //this.v -= this.v*0.7 // 0.7
            //}
            
        }
    }

    var circles = []
    var point
    
    p.setup = () => {
        p.createCanvas(window.innerWidth, window.innerHeight)
        length = Math.floor(window.innerWidth / 7)
        p.colorMode(p.HBS, 100)
        var x = Math.floor((window.innerWidth) / length)
        

        // tamanio de los circulitos mamones
        if (window.innerWidth > 1024) {
            blobRadius = window.innerWidth / 100
            blueLength = length/2.7
            redLength = length/2.3
            purpleLength = length/2.02
            yellowLength = length/1.87
            greenLength = length/1.67
            for(var i = 0; i < length; i++){
                circles.push(new Circle(x * i, window.innerHeight / 2, 0))
            }
        } else if (window.innerWidth <= 1024 && window.innerWidth > 768) {
            blobRadius = window.innerWidth / 70
            blueLength = length/3.1
            redLength = length/2.4
            purpleLength = length/2.02
            yellowLength = length/1.83
            greenLength = length/1.57
            for(var i = 0; i < length; i++){
                circles.push(new Circle(x * i, window.innerHeight / 2, 0))
            }
        } else {
            blobRadius = window.innerWidth / 50
            blueLength = length/5
            redLength = length/2.9
            purpleLength = length/2.02
            yellowLength = length/1.73
            greenLength = length/1.36
            for(var i = 0; i < length; i++){
                circles.push(new Circle(x * i, window.innerHeight / 2, 0))
            }
        }

        point = Math.floor(circles.length / 2 * Math.random())

        // Circulitos mamones se asignan aqui
        blue = new Blob(circles[Math.floor(blueLength)].pos.x, circles[Math.floor(blueLength)].pos.y, blobRadius, 0)
        red = new Blob(circles[Math.floor(redLength)].pos.x, circles[Math.floor(redLength)].pos.y, blobRadius, 1)
        purple = new Blob(circles[Math.floor(purpleLength)].pos.x, circles[Math.floor(purpleLength)].pos.y, blobRadius, 2)
        yellow = new Blob(circles[Math.floor(yellowLength)].pos.x, circles[Math.floor(yellowLength)].pos.y, blobRadius, 4)
        green = new Blob(circles[Math.floor(greenLength)].pos.x, circles[Math.floor(greenLength)].pos.y, blobRadius, 3)

        // Lineas del diablo
        firstLine = new Line(circles[Math.floor(blueLength)].pos.x, circles[Math.floor(blueLength)].pos.y, 0)
        secondLine = new Line(circles[Math.floor(redLength)].pos.x, circles[Math.floor(redLength)].pos.y, 1)
        thirdLine = new Line(circles[Math.floor(purpleLength)].pos.x, circles[Math.floor(purpleLength)].pos.y, 2)
        fourthLine = new Line(circles[Math.floor(yellowLength)].pos.x, circles[Math.floor(yellowLength)].pos.y, 3)
        fifthLine = new Line(circles[Math.floor(greenLength)].pos.x, circles[Math.floor(greenLength)].pos.y, 4)

        // Switches initial values
        blueSwitch = 0
        redSwitch = 0
        purpleSwitch = 0
        yellowSwitch = 0
        greenSwitch = 0
        
    }



    p.draw = () => {

        p.frameRate(30)
        p.clear()
        
        // console.log(point)
        for(var i = 0; i < point; i++){
            circles[i].setPos(circles[i].getPos().x, circles[i + 1].getPos().y)
            circles[i].setAngle(circles[i + 1].getAngle())
            circles[i].setRange(circles[i + 1].getRange())
            circles[i].draw()
        }
        for(var i = circles.length - 1; i > point; i--){
            circles[i].setPos(circles[i].getPos().x, circles[i - 1].getPos().y)
            circles[i].setAngle(circles[i - 1].getAngle())
            circles[i].setRange(circles[i - 1].getRange())
            circles[i].draw()
        }
        circles[point].draw()
        circles[point].update()


        // Dibujar circulitos

        p.noStroke()
        p.fill('#1000E8')
        blue.update(circles[Math.floor(blueLength)].pos.x, circles[Math.floor(blueLength)].pos.y)
        blue.generate()

        p.fill('#E80000')
        red.update(circles[Math.floor(redLength)].pos.x, circles[Math.floor(redLength)].pos.y)
        red.generate()

        p.fill('#9400EA')
        purple.update(circles[Math.floor(purpleLength)].pos.x, circles[Math.floor(purpleLength)].pos.y)
        purple.generate()

        p.fill('#E4EF00')
        yellow.update(circles[Math.floor(yellowLength)].pos.x, circles[Math.floor(yellowLength)].pos.y)
        yellow.generate()

        p.fill('#00E52B')
        green.update(circles[Math.floor(greenLength)].pos.x, circles[Math.floor(greenLength)].pos.y)
        green.generate()

        firstLine.update(circles[Math.floor(blueLength)].pos.x, circles[Math.floor(blueLength)].pos.y, p.mouseX, p.mouseY)
        firstLine.draw()

        secondLine.update(circles[Math.floor(redLength)].pos.x, circles[Math.floor(redLength)].pos.y, p.mouseX, p.mouseY)
        secondLine.draw()

        thirdLine.update(circles[Math.floor(purpleLength)].pos.x, circles[Math.floor(purpleLength)].pos.y, p.mouseX, p.mouseY)
        thirdLine.draw()

        fourthLine.update(circles[Math.floor(yellowLength)].pos.x, circles[Math.floor(yellowLength)].pos.y, p.mouseX, p.mouseY)
        fourthLine.draw()

        fifthLine.update(circles[Math.floor(greenLength)].pos.x, circles[Math.floor(greenLength)].pos.y, p.mouseX, p.mouseY)
        fifthLine.draw()
        
    }


    p.windowResized = () => {
        p.resizeCanvas(window.innerWidth, window.innerHeight)
        circles = []
        length = Math.floor(window.innerWidth / 7)
        
        var x = Math.floor((window.innerWidth) / length)

        if (window.innerWidth > 1024) {
            blobRadius = window.innerWidth / 100
            blueLength = length/2.7
            redLength = length/2.3
            purpleLength = length/2.02
            yellowLength = length/1.87
            greenLength = length/1.67
            for(var i = 0; i < length; i++){
                circles.push(new Circle(x * i, window.innerHeight / 2, 0))
            }
        } else if (window.innerWidth <= 1024 && window.innerWidth > 768) {
            blobRadius = window.innerWidth / 70
            blueLength = length/3.1
            redLength = length/2.4
            purpleLength = length/2.02
            yellowLength = length/1.83
            greenLength = length/1.57
            for(var i = 0; i < length; i++){
                circles.push(new Circle(x * i, window.innerHeight / 2, 0))
            }
        } else {
            blobRadius = window.innerWidth / 50
            blueLength = length/5
            redLength = length/2.9
            purpleLength = length/2.02
            yellowLength = length/1.73
            greenLength = length/1.36
            for(var i = 0; i < length; i++){
                circles.push(new Circle(x * i, window.innerHeight / 2, 0))
            }
        }
        
        point = Math.floor(circles.length / 2 * Math.random())
        
        blue = new Blob(circles[Math.floor(blueLength)].pos.x, circles[Math.floor(blueLength)].pos.y, blobRadius, 0)
        red = new Blob(circles[Math.floor(redLength)].pos.x, circles[Math.floor(redLength)].pos.y, blobRadius, 1)
        purple = new Blob(circles[Math.floor(purpleLength)].pos.x, circles[Math.floor(purpleLength)].pos.y, blobRadius, 2)
        yellow = new Blob(circles[Math.floor(yellowLength)].pos.x, circles[Math.floor(yellowLength)].pos.y, blobRadius, 4)
        green = new Blob(circles[Math.floor(greenLength)].pos.x, circles[Math.floor(greenLength)].pos.y, blobRadius, 3)

        firstLine = new Line(circles[Math.floor(blueLength)].pos.x, circles[Math.floor(blueLength)].pos.y, 0)
        secondLine = new Line(circles[Math.floor(redLength)].pos.x, circles[Math.floor(redLength)].pos.y, 1)
        thirdLine = new Line(circles[Math.floor(purpleLength)].pos.x, circles[Math.floor(purpleLength)].pos.y, 2)
        fourthLine = new Line(circles[Math.floor(yellowLength)].pos.x, circles[Math.floor(yellowLength)].pos.y, 3)
        fifthLine = new Line(circles[Math.floor(greenLength)].pos.x, circles[Math.floor(greenLength)].pos.y, 4)
    }

    // p.mousePressed = () => {
    // // point = Math.floor(p.mouseX / (window.innerWidth / circles.length))
    // // circles[point].setVelocity(circles[point].getVelocity()+1)
    // }

    p.mousePressed = () => {
        point = Math.floor(p.mouseX / (window.innerWidth / circles.length))
        circles[point].setVelocity(circles[point].getVelocity()+1)

        var vid1 = p.select(".bgImageContainer")
        var vid2 = p.select(".bgImageContainer2")
        var vid3 = p.select(".bgImageContainer3")
        var vid4 = p.select(".bgImageContainer4")
        var vid5 = p.select(".bgImageContainer5")
        var first = p.select(".first")
        var second = p.select(".second")
        var third = p.select(".third")
        var fourth = p.select(".fourth")
        var fifth = p.select(".fifth")

        first.volume = 0.7
        second.volume = 0.7
        third.volume = 0.7
        fourth.volume = 0.7
        fifth.volume = 0.7

        if (d <= 20) {
            if (greenSwitch === 0) {
                blueSwitch = 0
                redSwitch = 0
                purpleSwitch = 0
                yellowSwitch = 0
                greenSwitch = 1
                vid1.style('opacity', 0)
                vid2.style('opacity', 0)
                vid3.style('opacity', 0)
                vid4.style('opacity', 0)
                vid5.style('opacity', 0)
                first.pause()
                second.pause()
                third.pause()
                fourth.pause()
                fifth.play()
                // image.style('background-image', 'url("https://images.pexels.com/photos/1084542/pexels-photo-1084542.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260")')
                vid1.style('opacity', 0.5)
            } else {
                fifth.pause()
                vid1.style('opacity', 0)
                greenSwitch = 0
            }
        }

        if (blueFlag === 1) {
            if (blueSwitch === 0) {
                blueSwitch = 1
                redSwitch = 0
                purpleSwitch = 0
                yellowSwitch = 0
                greenSwitch = 0
                vid1.style('opacity', 0)
                vid2.style('opacity', 0)
                vid3.style('opacity', 0)
                vid4.style('opacity', 0)
                vid5.style('opacity', 0)
                first.play()
                second.pause()
                third.pause()
                fourth.pause()
                fifth.pause()
                // image.style('background-image', 'url("https://images.pexels.com/photos/1084542/pexels-photo-1084542.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260")')
                vid2.style('opacity', 0.5)
            } else {
                first.pause()
                vid2.style('opacity', 0)
                blueSwitch = 0
            }
        }

        if (redFlag === 1) {
            if (redSwitch === 0) {
                blueSwitch = 0
                redSwitch = 1
                purpleSwitch = 0
                yellowSwitch = 0
                greenSwitch = 0
                vid1.style('opacity', 0)
                vid2.style('opacity', 0)
                vid3.style('opacity', 0)
                vid4.style('opacity', 0)
                vid5.style('opacity', 0)
                first.pause()
                second.play()
                third.pause()
                fourth.pause()
                fifth.pause()
                // image.style('background-image', 'url("https://images.pexels.com/photos/1084542/pexels-photo-1084542.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260")')
                vid3.style('opacity', 0.5)
            } else {
                second.pause()
                vid3.style('opacity', 0)
                redSwitch = 0
            }
        }

        if (purpleFlag === 1) {
            if (purpleSwitch === 0) {
                blueSwitch = 0
                redSwitch = 0
                purpleSwitch = 1
                yellowSwitch = 0
                greenSwitch = 0
                vid1.style('opacity', 0)
                vid2.style('opacity', 0)
                vid3.style('opacity', 0)
                vid4.style('opacity', 0)
                vid5.style('opacity', 0)
                first.pause()
                second.pause()
                third.play()
                fourth.pause()
                fifth.pause()
                // image.style('background-image', 'url("https://images.pexels.com/photos/1084542/pexels-photo-1084542.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260")')
                vid4.style('opacity', 0.5)
            } else {
                third.pause()
                vid4.style('opacity', 0)
                purpleSwitch = 0
            }
        }

        if (yellowFlag === 1) {
            if (yellowSwitch === 0) {
                blueSwitch = 0
                redSwitch = 0
                purpleSwitch = 0
                yellowSwitch = 1
                greenSwitch = 0
                vid1.style('opacity', 0)
                vid2.style('opacity', 0)
                vid3.style('opacity', 0)
                vid4.style('opacity', 0)
                vid5.style('opacity', 0)
                first.pause()
                second.pause()
                third.pause()
                fourth.play()
                fifth.pause()
                // image.style('background-image', 'url("https://images.pexels.com/photos/1084542/pexels-photo-1084542.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260")')
                vid5.style('opacity', 0.5)
            } else {
                fourth.pause()
                vid5.style('opacity', 0)
                yellowSwitch = 0
            }
        }
    }

    p.touchStarted = () => {
        return null
    }
}