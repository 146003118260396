// npm run build

// Libraries
import React, {useState, useEffect} from 'react'
import { Helmet } from "react-helmet"
import { motion, AnimatePresence } from 'framer-motion'
// import { StaticImage } from "gatsby-plugin-image"

// Components
import SketchComponent from '../components/sketchComponent'
import Loading from '../components/loading'
import Title from '../components/title'
import Footer from '../components/footer'
import Videos from '../components/videos'

// Media
import clickme from "../static/assets/clickme.svg"
import bandcamp from "../static/assets/bandcamp.svg"
import spotifyfill from "../static/assets/spotifyfill.svg"
import youtubefill from  "../static/assets/youtubefill.svg"
import ap from  "../static/assets/applemusic.svg"
import OG from "../static/meta/meta.png"
import coverImage from "../static/assets/portada.jpg"
import info from "../static/assets/info.svg"
import cross from "../static/assets/cross.svg"
import "../styles/main.scss"

// Favicons
import favicon16 from "../static/assets/favicon16.png"
import favicon32 from "../static/assets/favicon32.png"
import favicon64 from "../static/assets/favicon64.png"

//////////////////////////
// Functional Component //
//////////////////////////

const IndexPage = () => {

  // Modal variables
  const [isActive, setActive] = useState("false");
  
  const handleToggle = () => {
    setActive(!isActive);
  };

  const [infoActive, setInfoActive] = useState(false);
  
  const infoToggle = () => {
    setInfoActive(!infoActive);
  };

  // Loading variables
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 5000)
  }, [])

  
  const [fadeOut, setFadeOut] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setFadeOut(true)
    }, 2000)
  })
    
  return (
    // Contenedor principal
  <div className="containerIndex">
    <Helmet>
          <title>Goeighta</title>
          <body className={!fadeOut ? 'react-cloak' : ''} />
          <meta charset="UTF-8"/>
          <meta name="distribution" content="global"/>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <meta name="description" content="Mi Cuarto Es Un Abismo — EP debút de Goeighta. DISPONIBLE AHORA."/>
          <meta name="keywords" content="goeighta, abismo, spotify, mexicali, lastdesertboys"/>
          <meta name="author" content="Goeighta"/>
          <meta name="robots" content="index"/>
          <meta http-equiv="X-UA-Compatible" content="ie=edge"/>
          <meta http-equiv="Content-Language" content="es"/>
          <meta property="og:title" content="Goeighta" />
          <meta property="og:description" content="Mi Cuarto Es Un Abismo — EP debút de Goeighta. DISPONIBLE AHORA." />
          <meta property="og:url" content="https://www.goeighta.com" />
          <meta property="og:site_name" content="Goeighta" />
          <meta property="og:locale" content="es_MX" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={OG} />
          <meta name="twitter:title" content="Goeighta" />
          <meta name="twitter:description" content="Mi Cuarto Es Un Abismo — EP debút de Goeighta. DISPONIBLE AHORA." />
          <meta name="twitter:site" content="@Goeighta" />
          <meta name="twitter:card" content="summary_large_image" />
          {/* Change twitter image src later */}
          <meta name="twitter:image:src" content={`https://www.goeighta.com/${OG}`} />
          <link rel="canonical" href="https://www.goeighta.com" />
          <link rel="icon" type="image/png" sizes="16x16" href={`${favicon16}`} />
          <link rel="icon" type="image/png" sizes="32x32" href={`${favicon32}`} />
          <link rel="shortcut icon" type="image/png" href={`${favicon64}`} />
          {/* <link rel="preload" href="../scripts/mainwave.js" as="script"></link>
          <link rel="preload" href="../scripts/p5.min.js" as="script"></link> */}
    </Helmet>
    <AnimatePresence>
        {loading &&
            <motion.div className="loadingDiv"
              initial={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
            >
              <Loading />
            </motion.div>
        }
      </AnimatePresence>

    {/* Info Element */}
    <div className={infoActive ? "info active" : "info"} onClick={infoToggle}>

      {/*Info button off */}
      <img src={info} className="ibutoff"/>

      {/*Info button on */}
      <img src={cross} className="ibuton"/>
      <div className="texto">
        <p className="firstchild">Hecho con &#10084; por</p>
        <p>Gluons y Klogui</p>
        <p className="contacto">Contacto:</p>
        <p><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/gluonsmx/">@gluonsmx</a></p>
        <p><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/klog101/">@klog101</a></p>
        <p className="lastchild"><a href="mailto:gluons@zohomail.com">gluons@zohomail.com</a></p>
      </div>
    </div>


      {/* Contenedor Sketch mainwave.js */}
      <SketchComponent />

      {/* Contenedor del titulo y quiza otra cosa */}
      <Title />
        
        
      {/* Contenedor del boton y modal box */}
      
      <div className = {isActive ? "modaltext" : "modaltext active"} onClick={handleToggle}>
        <div className={isActive ? "imgClick" : "imgClick active"}><img src={clickme} alt="clickme"/></div>
        <div className = "content">
          <div className="closeModal" onClick={handleToggle}>+</div>
          <img className="coverImage" src={coverImage} alt="Portada - Mi Cuarto Es Un Abismo"/>
          {/* <StaticImage imgClassName="coverImage" src={coverImage} alt="Portada - Mi Cuarto Es Un Abismo"/> */}
            <div className = "squaresub" id = "subtitle">    
              <h4>Goeighta - mi cuarto es un abismo.</h4>
            </div>
            <div className = "squaresub" id = "subtitle">    
              <p>Elige tu plataforma preferida</p>
            </div>
          <a target = "_blank" rel = "noopener noreferrer" href = "https://open.spotify.com/album/2ZoOCo4qVWyJdYc5RqQLWB?si=uA_kcDdYS5Wk-qMVeL4WEA">
            <div className = "square" id = "sp">
              <img src={spotifyfill} className="spfill"/>
              <p>&#9654; PLAY</p>
            </div>
          </a>
          <a target = "_blank" rel = "noopener noreferrer" href="https://youtube.com/playlist?list=OLAK5uy_k3pjUNVO5tP0cdvZd4KkE6d7kPcjHw_7s">
            <div className = "square" id = "yt">
              <img src={youtubefill} className="ytfill"/>
              <p>&#9654; PLAY</p>
            </div>
          </a>
          <a target = "_blank" rel = "noopener noreferrer" href="https://music.apple.com/mx/album/mi-cuarto-es-un-abismo-ep/1620572449">
            <div className = "square" id = "am">
              <img src={ap} className="amfill"/>
              <p>&#9654; PLAY</p>
            </div>
          </a>
          <a target = "_blank" rel = "noopener noreferrer" href="https://goeighta.bandcamp.com/album/mi-cuarto-es-un-abismo">
            <div className = "square" id = "bc">
              <img src={bandcamp} className="bcfill"/>
              <p>&#9654; PLAY</p>
            </div>
          </a>
        </div>
      </div>

      <div className={isActive ? "bgSeparator" : "bgSeparator active"} onClick={handleToggle}></div>


    {/* Contenedores de los videos */}
    <Videos />
    
        
    {/* Footer */}
    <Footer />

  </div>  
  )
}

export default IndexPage

/////////////////////
// Class Component //
/////////////////////

// class IndexPage extends React.Component {

//   state = {
//     loaded: false,
//   }

//   loaded = () => {
//     this.setState({
//         loaded: true
//     }) 
//   }

//   componentDidMount() {
//     this.setState({
//       loaded: true
//     })
//   }

//   render () {
    
//     const { loaded } = this.state

//     return (
//       <div className="container">
//         <Helmet>
//           <body className={!loaded ? 'react-cloak' : '' } />
//         </Helmet>

//         <div className="container">
//           <p>Hello World!</p>
//         </div>
//         <AnimatePresence>
//           {!loaded &&
//               <motion.div className="loadingDiv"
//                 initial={{ opacity: 1 }}
//                 exit={{ opacity: 0 }}
//                 transition={{ duration: 1 }}
//               >
//                 <Loading />
//               </motion.div>
//           }
//         </AnimatePresence>
//       </div>
//     )
//   }
// }

// export default IndexPage
