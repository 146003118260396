import React from 'react'
import title from "../../static/assets/title.svg"



const Title = ({data}) => {
    return (
        <div className="textEP">
          <img src={title} alt="Mi Cuarto Es Un Abismo"/>
          <p>EP deb&uacute;t de Goeighta</p>
          <div className="tracklistContainer">
            <p>01. me estoy hundiendo</p>
            <p>02. sol</p>
            <p>03. saturno</p>
            <p>04. mitomania</p>
            <p>05. en mi cuarto</p>
          </div>
        </div>
    )
}

export default Title