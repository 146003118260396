import React from 'react'
import loadingAnim from "../../static/assets/spinner.gif"

const Loading = () => {
    return (
        <div className="loadingDiv">
            <img src={loadingAnim} />
        </div>
    )
}

export default Loading