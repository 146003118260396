import React from 'react'
import SketchWrapper from '../../components/sketchWrapper'
import sketch from '../../scripts/mainwave'

const sketchContainer = () => {
    return (
        <div className="sketchContainer">
          <SketchWrapper sketch={sketch}/>
        </div>
    )
}

export default sketchContainer