import React from 'react'

import twLogo from  "../../static/assets/twlogo.svg"
import igLogo from  "../../static/assets/iglogo.svg"
import fbLogo from  "../../static/assets/fblogo.svg"
import apLogo from  "../../static/assets/aplogo.svg"
import ytLogo from  "../../static/assets/ytlogo.svg"
import spLogo from  "../../static/assets/splogo.svg"

const Footer = () => {
    return (
        <div className="footerSt">
            <div className="sContainer">
                <p className="follow">Sigue a Goeighta</p>
                <div className="images">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Goeighta/"><img src={fbLogo} /></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/Goeighta/"><img src={igLogo} /></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/Goeighta"><img src={twLogo} /></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCnBqRxRh_bOhJAhBKnlaUOA"><img src={ytLogo} /></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://music.apple.com/mx/artist/goeighta/1438473479"><img src={apLogo} /></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://open.spotify.com/artist/65WvWhsAtNHPtPCX9vzPMY"><img src={spLogo} /></a>
                </div>
            </div>
            <p className="copyright">Goeighta &copy; {new Date().getFullYear()}</p>
        </div>
    )
}

export default Footer